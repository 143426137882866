<template>
    <div :class="$style.container">
        <div :class="$style.allDone">Download report</div>
        <n-button ghost :class="$style.exitButton" @click="send">Download</n-button>
    </div>
</template>

<script>
import { useMessage } from "naive-ui";
import { useStore } from "vuex";
import resultsAPI from "../api/results";

export default {
    name: "DownloadReport",
    components: {},
    setup() {
        const store = useStore();
        const message = useMessage();

        const send = () => {
            store.dispatch("setLoading", true);
            resultsAPI
                .getReport()
                .then((response) => {
                    store.dispatch("setLoading", false);
                    if (response.status === 200) {
                        //message.success("Data sent successfully!");
                        const a = window.document.createElement("a");
                        a.href = window.URL.createObjectURL(new Blob([response.data], { type: "text/csv" }));
                        a.download = "reports.csv";
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    } else {
                        message.error("Error! Please try again later!");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    message.error("Error! Please try again later!");
                    store.dispatch("setLoading", false);
                });
        };

        return {
            send,
        };
    },
};
</script>

<style module>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    background: conic-gradient(from 201.76deg at 43.16% 52.18%, #606367 0deg, #929394 360deg);
}
.allDone {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #ffffff;
    margin-top: 45px;
}
.description {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #ffffff;
    width: 420px;
    margin-top: 18px;
}
.exitButton {
    margin-top: 50px;
    width: 190px;
    height: 50px;
    border-radius: 8px;
    color: white;
    margin-bottom: 20px;
    --n-border: 2px solid white !important;
}
</style>

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import {
    create,
    NButton,
    NForm,
    NFormItem,
    NInput,
    NConfigProvider,
    NMessageProvider,
    NDropdown,
    NIcon,
    NSelect,
    NPopover,
    NCheckbox,
    NSpin,
    NCarousel,
} from "naive-ui";

const naive = create({
    components: [
        NButton,
        NForm,
        NFormItem,
        NInput,
        NConfigProvider,
        NMessageProvider,
        NDropdown,
        NIcon,
        NSelect,
        NPopover,
        NCheckbox,
        NSpin,
        NCarousel,
    ],
});

createApp(App).use(store).use(router).use(naive).mount("#app");

<template>
    <n-icon :size="size">
        <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M31.5 0C25.2699 0 19.1797 1.84744 13.9995 5.30871C8.8194 8.76997 4.78197 13.6896 2.39781 19.4455C0.0136484 25.2013 -0.610156 31.5349 0.605278 37.6453C1.82071 43.7557 4.8208 49.3685 9.22615 53.7739C13.6315 58.1792 19.2443 61.1793 25.3547 62.3947C31.4651 63.6102 37.7987 62.9864 43.5545 60.6022C49.3104 58.218 54.23 54.1806 57.6913 49.0005C61.1526 43.8203 63 37.7301 63 31.5C62.9908 23.1485 59.6691 15.1417 53.7637 9.23626C47.8583 3.33086 39.8515 0.00917153 31.5 0ZM31.5 56.7C26.5159 56.7 21.6438 55.222 17.4996 52.453C13.3555 49.684 10.1256 45.7483 8.21825 41.1436C6.31092 36.5389 5.81188 31.472 6.78422 26.5837C7.75657 21.6954 10.1566 17.2052 13.6809 13.6809C17.2052 10.1566 21.6954 7.75656 26.5837 6.78421C31.4721 5.81186 36.5389 6.31091 41.1436 8.21824C45.7483 10.1256 49.684 13.3555 52.453 17.4996C55.222 21.6437 56.7 26.5159 56.7 31.5C56.6925 38.1811 54.0351 44.5865 49.3108 49.3108C44.5865 54.0351 38.1812 56.6925 31.5 56.7Z"
                :fill="color"
            />
            <path d="M45.0228 19.8229L28.3499 36.4958L21.1269 29.2729L16.6729 33.727L28.3499 45.404L49.4769 24.277L45.0228 19.8229Z" :fill="color" />
        </svg>
    </n-icon>
</template>

<script>
export default {
    name: "AllDoneIcon",
    props: {
        color: String,
        size: Number,
    },
};
</script>

<style scoped></style>

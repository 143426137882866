<template>
    <div :class="$style.container">
        <div :class="$style.title">Audits</div>
        <div :class="$style.description">Select an individual audit below to download and print.</div>
        <div :class="$style.schedule">
            <div :class="$style.scheduleTitle">August</div>
            <div :class="$style.list">
                <a :href="`/pdf/${item.file}`" v-for="item in items" :key="item.number" style="text-decoration: none" target="_black">
                    <div :class="$style.listItem">
                        <div :class="$style.listItemWeek">Week {{ item.number }}</div>
                        <div :class="$style.listItemTitle">{{ item.title }}</div>
                    </div>
                </a>
            </div>
        </div>
        <router-link to="/welcome" style="text-decoration: none">
            <n-button ghost :class="$style.backButton">Back</n-button>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "AuditsView",
    components: {},
    setup() {
        const items = [
            {
                number: 1,
                title: "Walking/Working Surfaces",
                file: "USGen_WalkWork-Week-1.pdf",
            },
            {
                number: 2,
                title: "Ladders",
                file: "USGen_Ladders-Week-2.pdf",
            },
            {
                number: 3,
                title: "Housekeeping",
                file: "USGen_Housekeep_Trash-Week-3.pdf",
            },
            {
                number: 4,
                title: "Emergency Resources",
                file: "USGen_EmergencyResources-Week-4.pdf",
            },
            {
                number: 5,
                title: "PPE",
                file: "USGen_Observs_MatHandling-Week-5.pdf",
            },
        ];

        return {
            items,
        };
    },
};
</script>

<style module>
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: conic-gradient(from 201.76deg at 43.16% 52.18%, #606367 0deg, #929394 360deg);
}
.title {
    font-weight: 800;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #ffffff;
}
.description {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #ffffff;
    margin-top: 41px;
}
.schedule {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 56px;
    width: 275px;
    background: rgba(66, 68, 71, 0.3);
    backdrop-filter: blur(12px);
    border-radius: 10px;
}
.scheduleTitle {
    display: flex;
    align-items: center;
    height: 50px;
    background: #ecb100;
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #353535;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-left: 30px;
    box-sizing: border-box;
}
.list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    padding-bottom: 30px;
}
.listItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 26px;
}
.listItemWeek {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: 0.005em;
    color: #ffffff;
}
.listItemTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: 0.005em;
    color: #ffffff;
}
.backButton {
    margin-top: 66px;
    --n-width: 131px !important;
    --n-height: 49px !important;
    --n-border: 2px solid white !important;
    border-radius: 8px;
    color: white;
    margin-bottom: 20px;
}
</style>

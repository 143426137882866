<template>
    <div :class="$style.container">
        <n-carousel show-arrow :on-update:current-index="slideShown">
            <div :class="$style.slide" v-for="slide in data" v-bind:key="slide.name">
                <img :class="$style.slideImage" :src="`/imgs/slides/${slide.name}`" v-if="slide.type === 'image'" />
                <video controls v-if="slide.type === 'video'" :class="$style.slideVideo">
                    <source :src="`/imgs/slides/${slide.name}`" :type="slide.content_type" />
                </video>
            </div>
        </n-carousel>
    </div>
    <n-button type="success" :class="$style.finishButton" v-if="finish" @click="finishLesson" color="yellow">Finish</n-button>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useRouter } from "vue-router";

export default {
    name: "ViewSlides",
    components: {},
    setup() {
        const router = useRouter();
        const finish = ref(false);
        const data = ref([
            {
                name: "Instructions.png",
                type: "image",
                content_type: "image/png",
            },
            {
                name: "Slide1.mp4",
                type: "video",
                content_type: "video/mp4",
                shown: false,
            },
            {
                name: "Slide2.mp4",
                type: "video",
                content_type: "video/mp4",
                shown: false,
            },
            {
                name: "Slide3.mp4",
                type: "video",
                content_type: "video/mp4",
                shown: false,
            },
            {
                name: "Slide4.mp4",
                type: "video",
                content_type: "video/mp4",
                shown: false,
            },
            {
                name: "Slide5.mp4",
                type: "video",
                content_type: "video/mp4",
                shown: false,
            },
            {
                name: "Slide6.mp4",
                type: "video",
                content_type: "video/mp4",
                shown: false,
            },
            {
                name: "Slide7.mp4",
                type: "video",
                content_type: "video/mp4",
                shown: false,
            },
            {
                name: "Slide8.mp4",
                type: "video",
                content_type: "video/mp4",
                shown: false,
            },
            {
                name: "Slide9.mp4",
                type: "video",
                content_type: "video/mp4",
                shown: false,
            },
            {
                name: "Slide10.mp4",
                type: "video",
                content_type: "video/mp4",
                shown: false,
            },
            {
                name: "Slide11.mp4",
                type: "video",
                content_type: "video/mp4",
                shown: false,
            },
            {
                name: "Slide12.mp4",
                type: "video",
                content_type: "video/mp4",
                shown: false,
            },
            {
                name: "Slide13.mp4",
                type: "video",
                content_type: "video/mp4",
                shown: false,
            },
            {
                name: "Slide14.mp4",
                type: "video",
                content_type: "video/mp4",
                shown: false,
            },
            {
                name: "Slide15.mp4",
                type: "video",
                content_type: "video/mp4",
                shown: false,
            },
        ]);

        const slideShown = (n) => {
            const newArr = [...data.value];
            newArr[n].shown = true;
            data.value = newArr;
            const finishTrue = data.value.every((item) => item.shown);
            if (finishTrue) {
                finish.value = true;
            }
        };

        onMounted(() => {
            const newArr = [...data.value];
            newArr[0].shown = true;
            data.value = newArr;
        });

        const finishLesson = () => {
            router.push("/all_done");
        };

        return {
            data,
            slideShown,
            finish,
            finishLesson,
        };
    },
};
</script>

<style module>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    height: 100vh;
}
.slide {
    height: calc(100% - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.slideImage {
    max-height: calc(100% - 50px);
    max-width: calc(100% - 50px);
}
.slideVideo {
    max-height: calc(100% - 50px);
    max-width: calc(100% - 50px);
}
.finishButton {
    position: fixed;
    bottom: 30px;
    left: calc(50% - 31px);
    --n-text-color: black !important;
    --n-text-color-hover: grey !important;
}
</style>

<template>
    <n-icon :size="size">
        <svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M61.145 0C92.1041 0 117.29 25.1858 117.29 56.145C117.29 87.1041 92.1041 112.29 61.145 112.29C30.1858 112.29 5 87.1041 5 56.145C5 25.1858 30.1858 0 61.145 0ZM61.145 8.21634C34.7157 8.21634 13.2163 29.7157 13.2163 56.145C13.2163 82.5742 34.7157 104.074 61.145 104.074C87.5742 104.074 109.074 82.5742 109.074 56.145C109.074 29.7157 87.5742 8.21634 61.145 8.21634Z"
                :fill="color"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M58.8488 37.036C61.6971 38.2246 81.6847 48.4622 81.6847 56.1198C81.6847 63.2954 63.6964 73.3303 59.1007 75.2474C57.3096 75.9924 55.2665 76.6935 53.3548 76.6935C51.8758 76.6935 50.4736 76.2717 49.3288 75.1324C48.4469 74.2615 46.0915 71.9335 46.0806 56.6018C46.0751 40.3497 48.5154 37.9096 49.3247 37.1003L49.3288 37.0963C52.1935 34.2589 56.8604 36.2034 58.8488 37.036ZM55.0253 45.0001C54.0558 50.4831 54.0394 63.5799 55.0089 68.7836C59.8182 67.0856 70.2365 60.2386 73.041 56.848C70.2475 53.556 59.9606 46.8515 55.0253 45.0001Z"
                fill="white"
            />
        </svg>
    </n-icon>
</template>

<script>
export default {
    name: "ModulesIcon",
    props: {
        color: String,
        size: Number,
    },
};
</script>

<style scoped></style>

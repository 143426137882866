import client from "./client";

const addResult = (data) => {
    return client
        .post(`/api/addResult`, data)
        .then((response) => {
            return response;
        })
        .catch((error) => error.response);
};

const getReport = () => {
    return client
        .get(`/api/getReport`)
        .then((response) => {
            return response;
        })
        .catch((error) => error.response);
};

const resultsAPI = {
    addResult,
    getReport,
};

export default resultsAPI;

<template>
    <n-icon :size="size">
        <svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_134_499)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M61.4655 0C67.6716 0 100.236 11.1797 104.547 15.4905C108.14 19.0838 108.085 21.9869 107.833 35.8999C107.734 41.4761 107.597 49.0734 107.597 59.5903C107.597 97.2869 64.4891 110.773 62.6541 111.326C62.2652 111.446 61.8654 111.501 61.4655 111.501C61.0657 111.501 60.6658 111.446 60.2769 111.326C58.4419 110.773 15.3335 97.2869 15.3335 59.5903C15.3335 49.0899 15.1966 41.4925 15.098 35.9163C15.053 33.4289 15.0143 31.2931 15.0016 29.4422L15 27.3536C15.047 20.4922 15.6909 18.1855 18.39 15.4905C22.6899 11.1797 55.254 0 61.4655 0ZM61.4655 8.21634C56.8205 8.21634 27.5156 18.5361 24.136 21.357C23.1391 22.3594 23.1007 24.1012 23.3143 35.763C23.4129 41.3829 23.5499 49.0187 23.5499 59.5903C23.5499 88.0791 54.8924 100.727 61.46 103.055C68.0221 100.716 99.3812 87.9969 99.3812 59.5903C99.3812 49.0077 99.5181 41.3665 99.6222 35.7465C99.8303 24.0958 99.792 22.3539 98.7348 21.3022C95.4209 18.5361 66.1105 8.21634 61.4655 8.21634Z"
                    :fill="color"
                />
                <path
                    d="M81.8502 46.0142C83.4552 44.4092 83.4552 41.8074 81.8502 40.2025C80.2453 38.5975 77.649 38.5975 76.044 40.2025L57.5956 58.6509L50.1407 51.185C48.5357 49.5801 45.9284 49.5801 44.329 51.185C42.7241 52.7844 42.7241 55.3917 44.3235 56.9967L54.687 67.3712C55.4594 68.1435 56.5056 68.5762 57.5956 68.5762L58.2425 68.525C59.093 68.3895 59.8852 67.989 60.4987 67.3712L81.8502 46.0142Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_134_499"
                    x="11"
                    y="0"
                    width="100.934"
                    height="168.14"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_134_499" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_134_499" result="shape" />
                </filter>
            </defs>
        </svg>
    </n-icon>
</template>

<script>
export default {
    name: "AllDoneIcon",
    props: {
        color: String,
        size: Number,
    },
};
</script>

<style scoped></style>

<template>
    <div :class="$style.container">
        <div :class="$style.title">Welcome</div>
        <div :class="$style.iconsContainer">
            <router-link to="/audits" style="text-decoration: none">
                <div :class="$style.select">
                    <AuditsIcon color="#FFC003" :size="122" />
                    <div :class="$style.selectTitle">Safety Team</div>
                </div>
            </router-link>
            <router-link to="/slides" style="text-decoration: none">
                <div :class="$style.select">
                    <ModulesIcon color="#FFC003" :size="122" />
                    <div :class="$style.selectTitle">Module</div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import AuditsIcon from "../icons/AuditsIcon.vue";
import ModulesIcon from "../icons/ModulesIcon.vue";

export default {
    name: "WelcomeView",
    components: { AuditsIcon, ModulesIcon },
    setup() {},
};
</script>

<style module>
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: conic-gradient(from 201.76deg at 43.16% 52.18%, #606367 0deg, #929394 360deg);
}
.title {
    font-weight: 800;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #ffffff;
}
.iconsContainer {
    margin-top: 67px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 341px;
}
.select {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.selectTitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #ffffff;
    margin-top: 28px;
}
</style>

<template>
    <div :class="$style.container">
        <div :class="$style.title">Please wait.</div>
        <div :class="$style.title">This may take a moment.</div>
        <n-spin size="large" style="margin-top: 15px; margin-bottom: 10px" />
    </div>
</template>

<script>
export default {
    name: "LoadingSign",
    components: {},
    setup() {},
};
</script>

<style module>
.container {
    position: fixed;
    top: calc(100vh / 2 - 75px);
    left: calc(100vw / 2 - 102px);
    z-index: 2500;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 15px 10px;
}
.title {
    font-size: 16px;
}
</style>

<template>
    <n-form :model="state" ref="formRef" :rules="state.rules" :class="$style.form" @keyup="enter">
        <n-form-item path="firstName">
            <n-input v-model:value="state.firstName" placeholder="First Name" />
        </n-form-item>
        <n-form-item path="lastName">
            <n-input v-model:value="state.lastName" placeholder="Last Name" />
        </n-form-item>
        <n-form-item path="storeNumber">
            <n-input v-model:value="state.storeNumber" placeholder="4-digit store number" />
        </n-form-item>
        <n-button ghost @click="submitForm">Continue</n-button>
    </n-form>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
    name: "SignInForm",
    setup() {
        const router = useRouter();
        const store = useStore();
        const formRef = ref(null);
        const state = reactive({
            firstName: "",
            lastName: "",
            storeNumber: "",
            rules: {
                firstName: {
                    required: true,
                    message: "Please enter your first name",
                    trigger: "input",
                },
                lastName: {
                    required: true,
                    message: "Please enter your last name",
                    trigger: "input",
                },
                storeNumber: {
                    required: true,
                    message: "Please enter a valid store number",
                    trigger: "submit",
                    validator: (rule, value) => {
                        const regex = /^\d{4}$/;
                        const check = regex.test(value);
                        if (!check) {
                            return false;
                        }
                        const n = Number(value);
                        if (n >= 6121 && n <= 6135) {
                            return true;
                        }
                        return false;
                    },
                },
            },
        });

        const send = () => {
            const data = {
                firstName: state.firstName,
                lastName: state.lastName,
                storeNumber: state.storeNumber,
            };
            store.dispatch("setUser", data);
            router.push("/welcome");
        };

        const validate = () => {
            try {
                formRef.value.validate((errors) => {
                    if (!errors) {
                        send();
                    } else {
                        console.log(errors);
                        ///message.error("Invalid");
                    }
                });
            } catch (error) {
                console.log(error);
            }
        };

        const submitForm = (e) => {
            e.preventDefault();
            validate();
        };

        const enter = (e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter") {
                validate();
            }
        };

        return {
            state,
            formRef,
            submitForm,
            enter,
        };
    },
};
</script>

<style module>
.form :global .n-form-item {
    display: flex;
    flex-direction: column;
    --n-feedback-text-color-error: white !important;
    width: 213px;
}
.form :global .n-input {
    --n-height: 49px !important;
    border-radius: 8px;
}

.form :global .n-input .n-input__input-el {
    font-size: 18px;
}
.form :global .n-button {
    margin-top: 26px;
    --n-width: 159px !important;
    --n-height: 49px !important;
    --n-border: 2px solid white !important;
    border-radius: 8px;
    color: white;
    margin-bottom: 20px;
}
</style>

<template>
    <n-config-provider :theme-overrides="themeOverrides">
        <n-message-provider>
            <router-view />
        </n-message-provider>
        <Loading v-if="loading" />
    </n-config-provider>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";
import Loading from "./components/Loading.vue";

const themeOverrides = {
    common: {
        primaryColor: "#FFC003",
        primaryColorHover: "#FFC003",
    },
    Button: {
        colorInfo: "#FFC003",
        colorHoverInfo: "#FFC003",
        colorPressedInfo: "#FFC003",
        colorFocusInfo: "#FFC003",
        borderInfo: "#FFC003",
        borderHoverInfo: "#FFC003",
        borderPressedInfo: "#FFC003",
        borderFocusInfo: "#FFC003",
        borderDisabledInfo: "#FFC003",
        colorDisabledInfo: "#FFC003",
    },
    Tabs: {
        tabTextColorActiveLine: "#FFC003",
    },
};

export default {
    name: "App",
    components: { Loading },
    setup() {
        const store = useStore();
        const loading = computed(() => store.state.loading);

        return {
            loading,
            themeOverrides,
        };
    },
};
</script>

<style>
#app {
    font-family: Mulish, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
}

html,
body {
    margin: 0 0;
}
</style>

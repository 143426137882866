import { createStore } from "vuex";

export default createStore({
    state: {
        loading: false,
        user: "",
    },
    mutations: {
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_USER(state, payload) {
            state.user = payload;
        },
    },
    actions: {
        setLoading({ commit }, payload) {
            commit("SET_LOADING", payload);
        },
        setUser({ commit }, payload) {
            commit("SET_USER", payload);
        },
    },
});

import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import ViewSlides from "../views/ViewSlides.vue";
import AllDone from "../views/AllDone.vue";
import DownloadReport from "../views/DownloadReport.vue";
import Welcome from "../views/Welcome.vue";
import Audits from "../views/Audits.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/welcome",
        name: "Welcome",
        component: Welcome,
    },
    {
        path: "/audits",
        name: "Audits",
        component: Audits,
    },
    {
        path: "/slides",
        name: "ViewSlides",
        component: ViewSlides,
    },
    {
        path: "/all_done",
        name: "AllDone",
        component: AllDone,
    },
    {
        path: "/report",
        name: "DownloadReport",
        component: DownloadReport,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;

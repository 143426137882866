<template>
    <div :class="$style.container">
        <img :class="$style.image" src="/imgs/logoCW-logo.png" />
        <GetStarted />
    </div>
</template>

<script>
import GetStarted from "../components/GetStarted.vue";

export default {
    name: "HomeView",
    components: { GetStarted },
    setup() {},
};
</script>

<style module>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: conic-gradient(from 201.76deg at 43.16% 52.18%, #606367 0deg, #929394 360deg);
}
</style>

<template>
    <div :class="$style.container">
        <div :class="$style.title">Get started</div>
        <div :class="$style.welcome">Welcome to OneFocus, your resource to everything for CW safety.</div>
        <SignInForm />
    </div>
</template>

<script>
import SignInForm from "./SignInForm.vue";

export default {
    name: "GetStarted",
    components: { SignInForm },
    setup() {},
};
</script>

<style module>
.container {
    display: flex;
    flex-direction: column;
    width: 303px;
    margin-left: 150px;
}
.title {
    font-weight: 800;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #ffffff;
}
.welcome {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #ffffff;
    margin-top: 25px;
    margin-bottom: 45px;
    padding-right: 12px;
}
</style>
